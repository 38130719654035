// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  environmentName: 'Development',

  production: false,

  graphQLEndpoint: "/graphql",
  subscriptionEndpoint: "wss://api-v2.dev.circlesquare.app/graphql",
  serverUrl: "https://api-v2.dev.circlesquare.app",

  googleAnalyticsId: undefined, // "G-T5ETQWNX2H
  hotjarTrackingCode: undefined, // 3822348
  hotjarVersion: 6, // 6

  stripePublishableKey: 'pk_test_51Oqwc7EqpKlINjuedw6BCxlYmGimge8dbZOqmVYWU2g6rBysI89PyEIch5ms4eT6HRURF7bo7XkOXqxQGYgSiLj800qbziDAug',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
